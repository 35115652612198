import React from "react"

import { Link } from "gatsby"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"

const ContactPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        description={data.page.frontmatter.description}
        title={data.page.frontmatter.metaTitle}
      />
      <article className="post-content page-template">
        <div className="post-content-body">
          <h1>{data.page.frontmatter.heading}</h1>
          <div dangerouslySetInnerHTML={{ __html: data.page.html }} />
        </div>
      </article>
    </Layout>
  )
}

export const CONTACT_PAGE_QUERY = graphql`
  query {
    site: site {
      siteMetadata {
        title
        author
      }
    }
    page: markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        metaTitle
        description
        heading
      }
      html
    }
  }
`

export default ContactPage
